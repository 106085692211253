// eslint-disable-next-line no-redeclare, no-unused-vars
/* global Image */
import React from 'react';
import { withTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { isWithinInterval, isSameDay, subDays, addDays } from 'date-fns';
import { toISODate, dateSameOrAfter, dateSameOrBefore, parseDate } from 'api/helpers/format/date';
import { locationName as formatLocationName } from 'api/helpers/format/location';
import { SQUARE_SMALL, FILM_RATIO_BIG } from 'config/images';
import Animals from 'components/Animals';
import { getUTCDate } from 'utils/date';
import ExperimentalFeature, { experiments, userTypes } from 'containers/ExperimentalFeature';
import environment from 'environment';
import { routes } from 'utils/routes';
import * as SearchConstants from 'api/helpers/search/constants';
import { useMediaQuery } from 'utils/hooks';
import useHideFavouritesCheck from 'hooks/useHideFavouritesCheck';
import useQuery from 'hooks/useQuery';
import { getRouteParamsSearchSits, hasRouteParams } from 'utils/urls';
import { LastMinBadge, NewBadge, LowApplicantsBadge } from '../Badges';
import { ListingCardVariant } from './ListingCard.constants';

import {
    AvatarStyled,
    BadgeContainerStyled,
    DatesStyled,
    DatesLabelWrapperStyled,
    FavouriteStyled,
    LinkStyled,
    ListingContainerStyled,
    ListingImageWrapperStyled,
    ListingImageContainerStyled,
    LocationStyled,
    ReviewingLabelStyled,
    TitleWrapperStyled,
    TitleStyled,
    ImageStyled,
} from './ListingCard.style';

export const productTriggerParams = new URLSearchParams(
    `?t=${JSON.stringify({ productTrigger: 'Find a House sit' })}`
);

const ListingCard = ({
    t,
    dateRange,
    listing,
    variant,
    isPaidOrExpired,
    isOutsideSearchPage,
    onClick,
    onCloseLowApplicantsPrompt,
    showLowApplicantsFeatures = false,
    showLowApplicantsPrompt = false,
    isSavedSearchDisplayRendered,
    ...otherProps
}) => {
    const { id, ownerAvatarPhoto, mainPhoto, openAssignments, title, location, animals } = listing;
    const shouldNotHideFavourites = !useHideFavouritesCheck();

    // only open link in a new tab if user is browsing from a desktop / laptop
    const mql = useMediaQuery('(min-width: 600px) and (pointer: fine)');
    const search = useQuery();
    const { pathname } = useLocation();

    const linkTarget = mql ? '_blank' : '_self';

    const cardImg = {
        default: environment.vars.listings.defaultImage,
        publicId: mainPhoto ? mainPhoto.publicId : null,
    };

    const cardAvatar = {
        default: environment.vars.profiles.defaultImage,
        publicId: ownerAvatarPhoto ? ownerAvatarPhoto.publicId : null,
    };
    const searchParams = new URLSearchParams(search.toString());
    if (isOutsideSearchPage) {
        searchParams.append('selectMembershipRegwall', 'true');
    }
    if (hasRouteParams('house-and-pet-sitting-assignments', pathname)) {
        searchParams.append('routeParams', JSON.stringify(getRouteParamsSearchSits(pathname)));
    }
    const href = `${routes.search.listingsDetail({
        ...listing.location,
        id: listing.id,
    })}?${productTriggerParams.toString()}${
        searchParams.toString() && `&${searchParams.toString()}`
    }`;

    const renderDatesAndReviewingLabel = () => {
        // We don't want to show date ranges that are under review
        // We also don't want to show anything in the past
        const openAssignmentsParsed = openAssignments.map((assignment) => ({
            ...assignment,
            startDate: parseDate(assignment.startDate),
            endDate: parseDate(assignment.endDate),
        }));
        const dateRangeParsed = {
            dateFrom: parseDate(dateRange.dateFrom),
            dateTo: parseDate(dateRange.dateTo),
        };
        const isReviewingAllDates = openAssignmentsParsed.every(
            (assignment) => assignment.isReviewing
        );
        const validAssignments = openAssignmentsParsed.filter(
            (assignment) =>
                (!isPaidOrExpired || isReviewingAllDates || !assignment.isReviewing) &&
                dateSameOrAfter(assignment.startDate, toISODate(new Date()))
        );
        let openAssignment;

        if (dateRangeParsed.dateFrom && dateRangeParsed.dateTo) {
            // Gets the first open assignment which has a start date that is within a date range.
            openAssignment = validAssignments.find(
                (assignment) =>
                    isSameDay(assignment.startDate, dateRangeParsed.dateFrom) ||
                    isWithinInterval(assignment.startDate, {
                        start: dateRangeParsed.dateFrom,
                        end: dateRangeParsed.dateTo,
                    })
            );
        } else if (dateRangeParsed.dateFrom) {
            // Gets the first open assignment which has a start date that is >= today
            openAssignment = validAssignments.find((assignment) =>
                dateSameOrAfter(assignment.startDate, dateRangeParsed.dateFrom)
            );
        } else {
            // eslint-disable-next-line prefer-destructuring
            openAssignment = validAssignments[0];
        }

        if (openAssignment) {
            return (
                <>
                    <DatesStyled variant={variant}>
                        {t('components_listingCard_dates', {
                            startDate: openAssignment.startDate,
                            endDate: openAssignment.endDate,
                        })}
                        {validAssignments.length > 1 && ' +'}
                    </DatesStyled>
                    {isReviewingAllDates && (
                        <ExperimentalFeature
                            experiment={experiments.PAUSED_SITS_REVIEWING_LABEL}
                            analytics={false}
                            showVariationToUsers={{
                                variation1: [userTypes.PaidUser],
                            }}
                            excludeCombo={[userTypes.ExpiredUser]}
                            control={<></>}
                            variation1={
                                <>
                                    <ReviewingLabelStyled data-testid="ListingCard__review__label">
                                        {t('components_listingCard_reviewing')}
                                    </ReviewingLabelStyled>
                                </>
                            }
                        />
                    )}
                </>
            );
        }

        return null;
    };

    const renderBadges = () => {
        const { publishedDate, publishedAssignment } = listing;
        const todayDate = new Date();
        const utcTodayDateZeroed = getUTCDate().setHours(0, 0, 0, 0);
        const publishedDateZeroed = new Date(publishedDate).setHours(0, 0, 0, 0);
        const isNew = dateSameOrAfter(publishedDate, subDays(getUTCDate(), 1));
        const oneWeekFromNow = addDays(todayDate, 7);
        let isLowApplicants = false;
        let isLastMinute = false;
        if (publishedAssignment && publishedAssignment.length) {
            for (let a = 0; a < publishedAssignment.length; a += 1) {
                const assignment = publishedAssignment[a];

                if (
                    dateSameOrBefore(assignment.startDate, oneWeekFromNow) &&
                    dateSameOrAfter(assignment.startDate, todayDate)
                ) {
                    isLastMinute = true;
                    break;
                }
            }
        }
        if (
            publishedAssignment &&
            publishedAssignment.length &&
            showLowApplicantsFeatures &&
            dateSameOrBefore(
                publishedDateZeroed,
                subDays(utcTodayDateZeroed, SearchConstants.LOW_APPLICANTS_LIMIT)
            )
        ) {
            for (let a = 0; a < publishedAssignment.length; a += 1) {
                const assignment = publishedAssignment[a];
                if (assignment.applicationsCount <= SearchConstants.LOW_APPLICANTS_LIMIT) {
                    isLowApplicants = true;
                    break;
                }
            }
        }
        if (!isNew && !isLastMinute && !isLowApplicants) return null;

        return (
            <BadgeContainerStyled data-testid="ListingCard__badges">
                {isLowApplicants && (
                    <LowApplicantsBadge
                        showLowApplicantsPrompt={showLowApplicantsPrompt}
                        onCloseLowApplicantsPrompt={onCloseLowApplicantsPrompt}
                        t={t}
                        center
                        data-testid="ListingCard__badge__lowApplicants"
                        isSavedSearchDisplayRendered={isSavedSearchDisplayRendered}
                    />
                )}
                {isNew && <NewBadge center data-testid="ListingCard__badge__new" />}
                {isLastMinute && (
                    <LastMinBadge center data-testid="ListingCard__badge__lastMinute" />
                )}
            </BadgeContainerStyled>
        );
    };

    const content = (
        <>
            <ListingImageWrapperStyled variant={variant} data-testid="ListingCard__image">
                <ListingImageContainerStyled>
                    <ImageStyled
                        imageId={cardImg && cardImg.publicId}
                        transformation={FILM_RATIO_BIG}
                        placeholderAlt={t('components_listingcard_placeholder_image')}
                        alt={t('components_listingcard_alt_location', {
                            location: formatLocationName(location, true),
                        })}
                    />
                </ListingImageContainerStyled>

                {variant === ListingCardVariant.LARGE && (
                    <>
                        <AvatarStyled size="medium" data-testid="ListingCard__avatar">
                            <ImageStyled
                                imageId={cardAvatar && cardAvatar.publicId}
                                transformation={SQUARE_SMALL}
                                placeholderUrl={cardAvatar.default}
                                placeholderAlt={t('components_listingcard_placeholder_image')}
                                alt={t('components_listingCard_owner_image_alt', {
                                    name: listing?.title,
                                })}
                            />
                        </AvatarStyled>
                        {renderBadges()}
                    </>
                )}
            </ListingImageWrapperStyled>

            {/* Render title */}
            {variant === ListingCardVariant.LARGE && (
                <TitleWrapperStyled>
                    <TitleStyled variant={variant} data-testid="ListingCard__title">
                        {title}
                    </TitleStyled>
                </TitleWrapperStyled>
            )}
            <DatesLabelWrapperStyled>{renderDatesAndReviewingLabel()}</DatesLabelWrapperStyled>

            {variant === ListingCardVariant.LARGE && (
                <LocationStyled variant={variant} data-testid="ListingCard__location">
                    {formatLocationName(location, true)}
                </LocationStyled>
            )}

            <Animals animals={animals} />
        </>
    );

    const linkWrapper = showLowApplicantsPrompt ? (
        <>{content}</>
    ) : (
        <LinkStyled to={href} target={linkTarget}>
            {content}
        </LinkStyled>
    );

    return (
        <ListingContainerStyled
            data-testid="ListingCard__container"
            aria-label={t('components_listingcard_arialabel', { title })}
            variant={variant}
            onClick={() => {
                if (onClick) {
                    onClick(listing);
                }
            }}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...otherProps}
        >
            {linkWrapper}
            {shouldNotHideFavourites ? <FavouriteStyled listingId={id} t={t} /> : null}
        </ListingContainerStyled>
    );
};

ListingCard.defaultProps = {
    // eslint-disable-next-line react/default-props-match-prop-types
    dateRange: {},
    // eslint-disable-next-line react/default-props-match-prop-types
    variant: ListingCardVariant.LARGE,
};

export { ListingCard };

export default withTranslation()(ListingCard);
