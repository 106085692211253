import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import Avatar from 'components/Avatar';
import CloudinaryImage from 'components/Image/CloudinaryImage';
import Favourite from 'containers/Favourite';
import { Span } from '../Typography';
import { ListingCardVariant } from './ListingCard.constants';
import { BadgeColumn } from '../Badges';

const ListingContainerStyled = styled.div`
    position: relative;
`;

const ListingImageWrapperStyled = styled.div`
    position: relative;
    padding-bottom: calc(100% / 1.5);
    margin-bottom: ${({ theme, variant }) => {
        if (variant === ListingCardVariant.LARGE) return theme.spacing[4];

        return theme.spacing[1];
    }};
`;

const ListingImageContainerStyled = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
`;

const ImageStyled = styled(CloudinaryImage)`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const FavouriteStyled = styled(Favourite)`
    position: absolute;
    padding: ${({ theme }) => theme.spacing['1.5']};
    top: ${({ theme }) => theme.spacing['0.5']};
    right: ${({ theme }) => theme.spacing['0.5']};
    z-index: 2;

    transition: all 0.2s ease-in-out;
    :hover {
        transform: scale(1.2);
    }
`;

// Title is currently only rendered in ListingCardVariant.LARGE
const TitleWrapperStyled = styled.div`
    position: relative;
    font-size: ${({ theme }) => theme.fontSizes.medLarge};
    height: 1.25em;
    margin-bottom: ${({ theme }) => theme.spacing['0.5']};
`;

const TitleStyled = styled.h3(
    ({ theme: { typography, palette, fontWeights } }) => css`
        ${typography.p};
        font-weight: ${fontWeights.bold};
        color: ${palette.text};
        position: absolute;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0;
    `
);

const AvatarStyled = styled(Avatar)`
    position: absolute;
    bottom: calc(${({ theme, size }) => theme.fontSizes[size]} * -1.5);
    right: ${({ theme }) => theme.spacing[1]};
    margin: 0;
`;

const LinkStyled = styled(Link)`
    display: block;
    text-decoration: none;
    color: inherit;

    /* this gradient provides a background colour for the */
    /* favourite icon incase the image behind is a light  colour */
    &::before {
        content: ' ';
        width: 100%;
        height: ${({ theme }) => theme.spacing[12]};
        background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
        z-index: 1;
        display: block;
        position: absolute;
    }
`;

const DatesLabelWrapperStyled = styled.div(
    ({ theme: { screenSize } }) => css`
        display: flex;
        flex-direction: column;
        align-items: start;

        // 375
        @media only screen and (min-width: ${screenSize.mediumHandset}px) {
            flex-direction: row;
            align-items: center;
        }
    `
);

const DatesStyled = styled(Span).attrs({
    styleOf: 'smallText',
})`
    display: block;
    max-width: 212px;
    margin-right: ${({ theme: { spacing } }) => spacing[1]};
    white-space: nowrap;
    margin-bottom: ${({ theme, variant }) => {
        if (variant === ListingCardVariant.SMALL) return theme.spacing[1];

        return 0;
    }};
`;

const ReviewingLabelStyled = styled.span(
    ({ theme: { fontSizes, spacing, palette, fontWeights } }) => css`
        color: ${palette.white};
        font-size: ${fontSizes.xsmall};
        padding: 0px ${spacing[1]};
        background-color: ${palette.grey[500]};
        font-weight: ${fontWeights.bold};
        text-transform: uppercase;
        border-radius: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    `
);

const LocationStyled = styled(Span).attrs({
    styleOf: 'smallText',
})`
    display: block;
    color: ${({ theme }) => theme.palette.textLight};
    margin-bottom: ${({ theme, variant }) => {
        if (variant === ListingCardVariant.SMALL) return theme.spacing['0.5'];
        return theme.spacing[1];
    }};
`;

const BadgeContainerStyled = styled(BadgeColumn)`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    position: absolute;
    left: ${({ theme }) => theme.spacing[2]};;
    padding-top: 4px;
    padding-bottom: 4px;
    bottom: ${({ theme }) => theme.spacing[3]};
'`;

const SkeletonListingCardImageStyled = styled.div`
    ${({ theme }) => css`
        aspect-ratio: 3 / 2;
        background: ${theme.palette.grey[300]};
        margin-bottom: 32px;
        width: 100%;
    `};
`;

const SkeletonListingCardTitleStyled = styled.div`
    ${({ theme }) => css`
        background: ${theme.palette.grey[300]};
        height: 24px;
        margin-bottom: 4px;
    `};
`;

const SkeletonListingCardDatesStyled = styled.div`
    ${({ theme }) => css`
        background: ${theme.palette.grey[300]};
        height: 20px;
        margin-bottom: 4px;
        width: 65%;
    `};
`;

const SkeletonListingCardLocationStyled = styled.div`
    ${({ theme }) => css`
        background: ${theme.palette.grey[300]};
        height: 20px;
        margin-bottom: 8px;
        width: 60%;
    `};
`;

const SkeletonListingCardPetRowStyled = styled.div`
    display: flex;
`;

const SkeletonListingCardPetIconStyled = styled.div`
    ${({ theme }) => css`
        background: ${theme.palette.grey[300]};
        height: 24px;
        margin-right: 4px;
        width: 24px;
    `};
`;

export {
    AvatarStyled,
    BadgeContainerStyled,
    DatesStyled,
    DatesLabelWrapperStyled,
    FavouriteStyled,
    LinkStyled,
    ListingContainerStyled,
    ListingImageWrapperStyled,
    ListingImageContainerStyled,
    LocationStyled,
    ReviewingLabelStyled,
    SkeletonListingCardDatesStyled,
    SkeletonListingCardImageStyled,
    SkeletonListingCardLocationStyled,
    SkeletonListingCardPetIconStyled,
    SkeletonListingCardPetRowStyled,
    SkeletonListingCardTitleStyled,
    TitleWrapperStyled,
    TitleStyled,
    ImageStyled,
};
